import React from 'react';
import SwapForm from './components/SwapForm'; // Импорт нового компонента
import './App.css'; // Подключение стилей для приложения (если есть)

function App() {
    return (
        <div className="App">
            <SwapForm />  {/* Рендеринг нового компонента SwapForm */}
        </div>
    );
}

export default App;
