import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { QRCodeCanvas } from 'qrcode.react';
import './i18n';
import { useTranslation } from 'react-i18next';

const SwapForm = () => {
    const { t, i18n } = useTranslation();
    const [swapDirection, setSwapDirection] = useState('usdtToOpg');
    const [usdtAmount, setUsdtAmount] = useState('');
    const [opgAmount, setOpgAmount] = useState('');
    const [opgAddress, setOpgAddress] = useState('');
    const [senderAddress, setSenderAddress] = useState('');
    const [network, setNetwork] = useState('BSC');
    const [resultAmount, setResultAmount] = useState(0);
    const [depositAddress, setDepositAddress] = useState('');
    const [swapStatus, setSwapStatus] = useState('');
    const [swapId, setSwapId] = useState('');

    useEffect(() => {
        if (swapDirection === 'usdtToOpg' && usdtAmount) {
            setResultAmount(usdtAmount);
        } else if (swapDirection === 'opgToUsdt' && opgAmount) {
            setResultAmount(opgAmount);
        }
    }, [usdtAmount, opgAmount, swapDirection]);

    const handleSwap = async (e) => {
        e.preventDefault();
        setSwapStatus(t('Processing'));  // Локальное сообщение о начале процесса

        try {
            const data = swapDirection === 'usdtToOpg'
                ? { usdtAmount, opgAddress, senderAddress, network }
                : { opgAmount, usdtAddress: opgAddress, senderAddress, network };

            const response = await axios.post(`${process.env.REACT_APP_API_URL}/swap`, data);
            setDepositAddress(response.data.depositAddress);
            setSwapId(response.data.swapId);

            const intervalId = setInterval(async () => {
                try {
                    const statusResponse = await axios.get(`${process.env.REACT_APP_API_URL}/swap/status`, {
                        params: { swapId: response.data.swapId, lang: i18n.language }  // Передаем язык пользователя на сервер
                    });

                    if (statusResponse.data.status === 'success') {
                        setSwapStatus(statusResponse.data.message);  // Используем сообщение с сервера
                        clearInterval(intervalId);
                    } else if (statusResponse.data.status === 'error') {
                        setSwapStatus(statusResponse.data.message);  // Используем сообщение с сервера
                        clearInterval(intervalId);
                    } else {
                        setSwapStatus(statusResponse.data.message);  // Используем сообщение с сервера
                    }
                } catch (error) {
                    console.error('Ошибка при проверке статуса свапа:', error);
                }
            }, 60000);

        } catch (error) {
            console.error('Ошибка во время свапа:', error.response ? error.response.data : error.message);
            setSwapStatus(t('swap_error'));
        }
    };

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    return (
        <div className="bg-gray-900 min-h-screen flex justify-center items-center p-4">
            <div className="swap-container max-w-md w-full bg-gray-800 p-8 rounded-lg shadow-lg">
                <div className="flex justify-center mb-4">
                    <img src="/logo.png" alt="Logo" className="w-28 h-28" />
                </div>

                <h1 className="text-2xl font-bold text-white text-center mb-6">{t('Crypto Swap')}</h1>

                <div className="flex justify-between mb-4">
                    <button
                        className={`flex-1 py-2 px-4 rounded-l-lg ${swapDirection === 'usdtToOpg' ? 'bg-blue-600 text-white' : 'bg-gray-700 text-gray-300'}`}
                        onClick={() => setSwapDirection('usdtToOpg')}
                    >
                        {t('USDT to OPG')}
                    </button>
                    <button
                        className={`flex-1 py-2 px-4 rounded-r-lg ${swapDirection === 'opgToUsdt' ? 'bg-blue-600 text-white' : 'bg-gray-700 text-gray-300'}`}
                        onClick={() => setSwapDirection('opgToUsdt')}
                    >
                        {t('OPG to USDT')}
                    </button>
                </div>

                <form onSubmit={handleSwap} className="space-y-4">
                    <div>
                        <label className="block text-sm text-gray-200">{t('Network')}:</label>
                        <select 
                            value={network} 
                            onChange={(e) => setNetwork(e.target.value)} 
                            required 
                            className="w-full mt-1 p-2 rounded bg-gray-700 text-gray-200"
                        >
                            <option value="BSC">BSC</option>
                            <option value="TRC-20">TRC-20</option>
                            <option value="TON">TON</option>
                        </select>
                    </div>

                    {swapDirection === 'usdtToOpg' ? (
                        <div>
                            <label className="block text-sm text-gray-200">{t('USDT Amount')}:</label>
                            <input
                                type="number"
                                value={usdtAmount}
                                onChange={(e) => setUsdtAmount(e.target.value)}
                                required
                                className="w-full mt-1 p-2 rounded bg-gray-700 text-gray-200"
                            />
                        </div>
                    ) : (
                        <div>
                            <label className="block text-sm text-gray-200">{t('OPG Amount')}:</label>
                            <input
                                type="number"
                                value={opgAmount}
                                onChange={(e) => setOpgAmount(e.target.value)}
                                required
                                className="w-full mt-1 p-2 rounded bg-gray-700 text-gray-200"
                            />
                        </div>
                    )}

                    <div>
                        <label className="block text-sm text-gray-200">{swapDirection === 'usdtToOpg' ? t('OPG Address (To)') : t('USDT Address (To)')}:</label>
                        <input
                            type="text"
                            value={opgAddress}
                            onChange={(e) => setOpgAddress(e.target.value)}
                            required
                            className="w-full mt-1 p-2 rounded bg-gray-700 text-gray-200"
                        />
                    </div>

                    <div>
                        <label className="block text-sm text-gray-200">{t('Sender Address')}:</label>
                        <input
                            type="text"
                            value={senderAddress}
                            onChange={(e) => setSenderAddress(e.target.value)}
                            required
                            className="w-full mt-1 p-2 rounded bg-gray-700 text-gray-200"
                        />
                    </div>

                    <div>
                        <label className="block text-sm text-gray-200">{swapDirection === 'usdtToOpg' ? t('You will receive') : t('You will send')}:</label>
                        <p className="text-lg text-gray-200">{resultAmount} {swapDirection === 'usdtToOpg' ? 'OPG' : 'USDT'}</p>
                    </div>

                    <button type="submit" className="w-full py-2 px-4 bg-blue-600 hover:bg-blue-700 rounded text-white font-bold">
                        {t('Swap')}
                    </button>
                </form>

                {depositAddress && (
                    <div className="result mt-6 flex flex-col items-center">
                        <p className="text-gray-200">{t('Please send')}:</p>
                        <p className="deposit-address text-yellow-300 font-bold">{depositAddress}</p>
                        <div className="qr-code-container mt-4">
                            <QRCodeCanvas value={depositAddress} size={128} />
                        </div>
                    </div>
                )}

                {swapStatus && (
                    <p className="status mt-4 text-green-500 text-center">{swapStatus}</p>
                )}

                <div className="language-switcher flex justify-center mt-6 space-x-4">
                    <button onClick={() => changeLanguage('en')} className="flex items-center text-white">
                        <img src="https://cdn-icons-png.flaticon.com/512/197/197374.png" alt="English" className="w-6 h-6 mr-2" />
                        EN
                    </button>
                    <button onClick={() => changeLanguage('ru')} className="flex items-center text-white">
                        <img src="https://cdn-icons-png.flaticon.com/512/197/197408.png" alt="Russian" className="w-6 h-6 mr-2" />
                        RU
                    </button>
                    <button onClick={() => changeLanguage('de')} className="flex items-center text-white">
                        <img src="https://cdn-icons-png.flaticon.com/512/197/197571.png" alt="German" className="w-6 h-6 mr-2" />
                        DE
                    </button>
                    <button onClick={() => changeLanguage('es')} className="flex items-center text-white">
                        <img src="https://cdn-icons-png.flaticon.com/512/197/197593.png" alt="Spanish" className="w-6 h-6 mr-2" />
                        ES
                    </button>
                </div>

                <div className="instructions mt-6 text-center text-gray-400 text-sm">
                    <h3 className="font-semibold">{t('Swap Instructions')}</h3>
                    <p>{t('instruction_step_1')}</p>
                    <p>{t('instruction_step_2')}</p>
                    <p>{t('instruction_step_3')}</p>
                    <p>{t('instruction_step_4')}</p>
                    <p>{t('instruction_step_5')}</p>
                    <p>{t('instruction_step_6')}</p>
                    <p>{t('instruction_step_7')}</p>
                </div>
            </div>
        </div>
    );
};

export default SwapForm;
